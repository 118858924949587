<template>
  <div class="filter">
    <div class="top-title">
      <p class="title">按条件筛选</p>
    </div>
    <div class="center">
      <div class="item">
        <p class="title-text">特色类别：</p>
        <div
            class="cdiv"
            @click="click1(v,index,0)"
            v-for="(v, index) in Item1.arr1"
            :key="index"
        >
          <img :src="index==value1?'/images/towns/circle-selected.png':'/images/towns/circle-normal.png'"
               class="item-circle-img"/>
          <p class="item-text">{{ v.label }}</p>
        </div>
      </div>

      <div class="item">
        <p class="title-text">产业类别：</p>
        <div
            class="cdiv"
            @click="click1(v,index,1)"
            v-for="(v, index) in Item1.arr2"
            :key="index"

        >
          <img :src="value2.indexOf(v.value)>=0?'/images/towns/check.png':'/images/towns/normal.png'"
               class="item-circle-img"/>
          <p class="item-text" :style="index==Item1.arr2.length-1?{'margin-right':0,}:{}">{{ v.label }}</p>
        </div>
      </div>

      <div class="item">
        <p class="title-text">人口数量：</p>
        <div
            class="cdiv"
            @click="click1(v,index,2)"
            v-for="(v, index) in Item1.arr3"
            :key="index"
        >
          <img :src="index==value3?'/images/towns/circle-selected.png':'/images/towns/circle-normal.png'"
               class="item-circle-img"/>
          <p class="item-text">{{ v.label }}</p>
        </div>
      </div>

      <div class="item">
        <p class="title-text">行政区域：</p>
        <a-dropdown>
          <a class="ant-dropdown-link" @click.prevent style="color: white;font-size: 0.14rem;margin-right: 0.73rem">
            {{ cityItem ? cityItem : '请选择城市' }}
            <DownOutlined/>
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item v-for="(item,index) in cityList.data" :key="index" @click="click1(item,index,3)">
                <a href="javascript:;">{{ item.name }}</a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>

        <a-dropdown>
          <a class="ant-dropdown-link" @click.prevent style="color: white;font-size: 0.14rem">
            {{ countyItem ? countyItem : '请选择区县' }}
            <DownOutlined/>
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item v-for="(item,index) in countyList.data" :key="index" @click="click1(item,index,4)">
                <a href="javascript:;">{{ item.name }}</a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, reactive, ref} from 'vue'
import api from '@/api/index'
import {DownOutlined} from '@ant-design/icons-vue'

export default {
  name: 'townsFilter',
  components: {
    DownOutlined,
  },
  emits: ['search'],
  setup(props, ctx) {
    const value1 = ref(-1)
    const value2 = ref([])
    const value3 = ref(-1)
    const cityItem = ref('')
    const countyItem = ref('')
    const searchData = reactive({})
    const cityList = reactive([])
    const countyList = reactive([])


    const Item1 = reactive({
      arr1: [
        {
          label: '国家级特色小镇',
          value: 'NATIONAL',
        }, {
          label: '省级特色小镇',
          value: 'PROVINCIAL',
        }, {
          label: '百镇建设行动试点镇',
          value: 'HUNDRED_CONSTRUCTION',
        }, {
          label: '省级森林小镇',
          value: 'PROVINCIAL_FOREST',
        },
      ],
      arr2: [
        {
          label: '旅游休闲',
          value: 'TOUR',
        }, {
          label: '现代农业',
          value: 'AGRICULTURE',
        }, {
          label: '商贸物流',
          value: 'BUSINESS',
        }, {
          label: '加工制造',
          value: 'MANUFACTURING',
        }, {
          label: '文化创意',
          value: 'CURTURAL',
        }, {
          label: '科技教育',
          value: 'TECHNOLOGY',
        },
      ],
      arr3: [
        {
          label: '5万以下',
          value: 'FIVE',
        }, {
          label: '5万-10万',
          value: 'TEN',
        }, {
          label: '10万以上',
          value: 'MORE',
        },
      ],
    })



    const click1 = (v, index, type) => {
      if (type == 0) {
        if (value1.value == index) {
          value1.value = -1
        } else {
          value1.value = index
        }
        searchData.category = value1.value >= 0 ? v.value : ''
      } else if (type == 1) {
        let i = value2.value.indexOf(v.value)

        if (i >= 0) {
          value2.value.splice(i, 1)
        } else {
          value2.value.push(v.value)
        }
        searchData.industryCategory = value2.value
      } else if (type == 2) {
        if (value3.value == index) {
          value3.value = -1
        } else {
          value3.value = index
        }
        searchData.population = value3.value >= 0 ? v.value : ''
      } else if (type == 3) {
        if (cityItem.value != v.name) {
          countyItem.value = ''
          searchData.county = ''
        }
        cityItem.value = v.name
        searchData.city = v.code

        getCounty(v.code)
      } else {
        countyItem.value = v.name
        searchData.county = v.code
      }

      ctx.emit('search', searchData)
    }
    const handleChange = (value) => {
      console.log(value) // { key: "lucy", label: "Lucy (101)" }
    }
    const getCity = async () => {
      const params = {
        provinceCode: '51'
      }
      const res = await api.cityList(params)
      cityList.data = res ? res : []
    }
    const getCounty = async (val) => {
      const params = {
        cityCode: val
      }
      const res = await api.countyList(params)
      countyList.data = res ? res : []
    }
    onMounted(() => {
      getCity()
    })
    return {
      value1,
      value2,
      value3,
      cityList,
      countyList,
      cityItem,
      countyItem,
      click1,
      Item1,

      handleChange
    }
  },
}
</script>

<style lang="scss" scoped>
.filter {
  height: 2.13rem;
  background: rgba(0, 0, 0, 0.29);
  width: 100%;
  align-items: center;
  flex-direction: column;
  display: flex;

  .top-title {
    align-items: center;
    width: 12rem;
    flex: 1;
    display: flex
  }

  .title {
    color: white;
    font-size: 0.18rem;
    text-align: left;
  }

  .center {
    background: rgba(0, 0, 0, 0.39);
    width: 12rem;
    height: 1.547rem;
    display: flex;
    flex-direction: column;
  }
}

.item {
  display: flex;
  margin-top: 0.08rem;
}

.title-text {
  color: white;
  font-size: 0.14rem;
  margin-left: 0.4rem;
  margin-right: 0.1rem;
}

.cdiv {
  display: flex;
  align-items: center;
  cursor: pointer;

}

.item-circle-img {
  width: 0.14rem;
  height: 0.14rem;
}

.item-text {
  color: white;
  font-size: 0.14rem;
  margin-right: 0.73rem;
  margin-left: 0.08rem;
  //line-height: 0.18rem;

}
</style>
