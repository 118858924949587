<template>
  <div class="wrap">
    <header class="header">
      <SearchBox placeholder="寻找小镇" @searchClick='searchClickEvent'  size='small'
                 style="margin-top:1.35rem"/>
      <div style="flex:1"></div>
      <TownsFilter style="margin-bottom: 0" @search="filterClick"/>
    </header>
    <div class="d-f-c">
      <div class="content">
        <div class="content-header">
          <h3 class="content-total-text">共找到{{ list.length }}条结果</h3>
          <div class="content-rz" @click="goPage('apply')">
            <span class="content-rz-text">精品小镇马上入驻</span>
          </div>
        </div>
        <ul class="content-list">
          <li
              v-for="item in list"
              :key="item"
              class="list-item"
              @click="goPage(`/townsdetail?tid=${item.id}&tn=${item.name}`)"
          >
            <TownItem :data="item"/>
          </li>
        </ul>
        <NoData :visible="!list.length"/>
        <!--        <div class="page">-->
        <!--          <Page-->
        <!--              class="page-tools"-->
        <!--              :size="params.pageSize"-->
        <!--              :current="pageNum"-->
        <!--              :total="total"-->
        <!--              @pageChange="pageChange"-->
        <!--          />-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>
<script>

import NoData from '@/components/common/nodata/index.vue'
import {useRoute} from 'vue-router'
import { useParams} from '@/hooks/useList'
import api from '@/api'
import SearchBox from '@/components/common/searchbox/index.vue'
import TownsFilter from '../../components/towns/townsFilter'
import TownItem from '@/components/common/townitem'
import {ref} from "vue";

export default {
  name: 'towns',
  components: {
    // Page,
    NoData,
    SearchBox,
    TownsFilter,
    TownItem
  },
  setup() {
    const list = ref([])
    const route = useRoute()
    const {params, search,} = useParams({
      pageNum: route.query?.pn ?? 1,
      name: route.query?.kw ?? '',
      category: route.query?.category ?? '',
      industryCategory: route.query?.industryCategory ?? '',
      population: route.query?.population ?? '',
      city: route.query?.city ?? '',
      county: route.query?.county ?? '',
      pageSize: 8
    })
    const searchData = ref(params.name)
    getList()
    // onBeforeRouteUpdate((to) => {
    //   searchData.value = to.query?.kw
    //   search({
    //     pageNum: to.query?.pn,
    //     name: to.query?.kw,
    //     category: to.query?.category ?? '',
    //     industryCategory: to.query?.industryCategory ?? [],
    //     population: to.query?.population ?? '',
    //     city: to.query?.city ?? '',
    //     county: to.query?.county ?? '',
    //   })
    // })

    async function getList() {

      params.name = searchData.value
      const res = await api.searchTowns(params)
      list.value = res ? res : []
    }

    const searchClickEvent = (text) =>{

      searchData.value = text;
      getList()
    }
    const filterClick = (d) =>{
      params.category  = d.category??'';
      params.industryCategory = (d.industryCategory || []).toString()??'';
      params.population =  d.population??''
      params.city = d.city??'';
      params.county = d.county ?? ''
      getList();
      console.log('000:',d);
    }


    return {
      params,
      list,
      searchData,
      search,
      getList,
      filterClick,
      searchClickEvent
    }

  },
  methods: {
    goPage(url) {
      this.$router.push(url)
    },
    resetParams() {
      this.$router.push({
        query: {
          pn: 1
        }
      })
    },
    pageChange(page, kw, data) {
      this.$router.push({
        query: {
          ...this.$route.query,
          pn: page,
          kw: kw,
          ...data
        }
      })
    },
    searchClick(kw) {
      this.pageChange(1, kw)
    },
    searchKeyWords(data) {
      this.pageChange(1, this.searchData, data)

    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  min-height: calc(100vh - 1.06rem);
  //background: url("/images/common/bg.png");

  .header {
    height: 4.39rem;
    width: 100%;
    background: url("/images/towns/banner.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .header-title {
      width: 7.17rem;
    }
  }

  .content {
    width: 12rem;
    min-height: calc(100vh - 5.6rem);
    padding: 0.6rem 0;
    position: relative;


    .content-header {
      display: flex;
      justify-content: space-between;
    }

    .content-total-text {
      font-size: 0.2rem;
      font-weight: 400;
      color: #333333;
    }

    .content-rz {

      background: #01398D;

      align-items: center;
      justify-content: center;
      cursor: pointer;
      display: flex;
      border-radius: 0.03rem;

      .content-rz-text {
        font-size: 0.14rem;
        color: white;
        padding: 0.06rem 0.2rem;
      }
    }

    .content-tag {
      top: 0.15rem;
      position: absolute;
      background: #A37F4B;
      font-size: 0.14rem;
      padding: 0.08rem 0.1rem;
      border-radius: 0.04rem;
      color: #fff;
      cursor: pointer;
    }

    .content-title {
      font-size: 0.18rem;
      font-weight: bold;
      color: #333333;
    }

    .content-subTitle {
      font-size: 0.14rem;
      font-weight: normal;
      color: #999999;
    }

    .content-list {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      margin-top: 0.4rem;

      .list-item {
        position: relative;

        margin-bottom: 0.8rem;
        transition: all 300ms;

        &:not(:nth-of-type(4n)) {
          margin-right: 0.16rem;
        }

        &:hover {
          transition: all 300ms;
          transform: scale(1.05);
          cursor: pointer;
        }

        .item-border {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 5.12rem;
        }

        .item-cover {
          display: block;
          width: 100%;
          height: 5.12rem;
          object-fit: cover;
        }

        .item-title {
          margin-top: 0.14rem;
          font-size: 0.16rem;
          font-weight: normal;
          color: #333333;
          @extend .text-oneLine;
        }
      }
    }
  }

  .page {
    display: flex;
    justify-content: center;
    margin-top: 0.8rem;
  }
}
</style>
